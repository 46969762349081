@import "~bootstrap/scss/bootstrap";
@import "./basedata/variables";

body {
  min-height: 100vh;
 }  // background: rgb(236,236,236)

.Container {
  min-height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .logo {
    max-width: 130px; }

  .logos {
    text-align: center;
    margin: 1em 0; }

  .logo-bet {
    display: block;
    width: 45%;
    margin: 0 auto; } }

.card {
  padding: 24px;
  background-color: #fff;
  width: 80%;
  max-width: 400px;
  border-radius: 8px;
  transition: all ease 2s;

  h1 {
    width: 100%;
    @include fontDefault;
    font-size: 1.7em;
    color: var(--pm-dark);

    span {
      @include fontSuper; } }

  p {
    @include fontDefault;
    font-size: 1em;
    line-height: 1.3em;
    color: $gray-600;

    &.loading {
      min-height: 300px;
      text-align: center;
      background-color: #c3281f;
      color: #ffffff;
      justify-content: center;

      h1 {
        display: none; } }

    .form-group {
      padding: 8px;

      .primary {
        background-color: var(--pm-dark);
        color: #ffffff; } }
    .text-muted {
      color: #cb6a6a !important; } } }

.form-wrapper {
  padding-top: 24px;

  .form-floating {
    margin: 8px 0;
    label {
      @include fontDefault;
      padding-bottom: 4px;
      color: $gray-600; }

    input {
      border-radius: 4px;
      @include fontDefault;
      font-size: 1em;
      color: $gray-700; } } }

.form-submit {
  border-top: 1px solid tint(var(--pm-primary), 80%);
  padding-top: 24px;
  margin-top: 24px;

  .btn-primary {
    border-color: var(--pm-dark) !important;
    background-color: var(--pm-dark) !important;
    @include fontTitle;
    color: $white;

    &.btn-document-choose {
      background-color: $white !important;
      color: var(--pm-dark);
      margin: 0 0.30em;

      i {
        font-size: 22px; } } } }

.help-link {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  padding: 8px 0;
  @include fontDefault;
  font-size: 0.8em;
  text-decoration: none;
  color: var(--pm-primary); }

.form-check {
  display: flex;
  align-items: center; }

.form-check-label {
  text-transform: uppercase;
  padding: 8px 0;
  @include fontDefault;
  font-size: 0.8em;
  line-height: 1.2em;
  text-decoration: none;
  color: $gray-600;
  margin-left: 16px;

  a {
    @include fontTitle;
    color: var(--pm-dark); } }

.label-error {
  @include fontDefault;
  font-size: 0.8em;
  color: $red-400; }

.step-counter {
  width: 100%;
  text-align: center;
  margin-top: 8px;
  padding: 0;

  li {
    display: inline-block;
    margin: 0 8px;
    border-radius: 50%;
    border: 1px solid $white;
    padding: 4px;

    &.active {
      background-color: $white;
      border-color: tint(var(--pm-primary), 80%); } } }

.copyright {
  text-align: center;
  @include fontDefault;
  font-size: 0.8em;
  color: $gray-500;

  a {
    text-decoration: none;
    color: $white;
    vertical-align: middle;
    &:hover {
      color: var(--pm-primary); } }

  .logo-bepass {
    max-width: 90px;
    margin-left: 6px; } }

.btn-document-choose {
  width: 100%;
  display: block;
  background-color: $white !important;
  color: var(--pm-dark) !important;
  border-color: $gray-400 !important;
  margin-bottom: 16px;
  // box-shadow: -1px 1px 0px 1px var(--pm-primary)
  text-align: left !important;

  i {
    display: block;
    font-size: 30px;
    margin-right: 16px; }

  p {
    margin: 2px 0 0 0;
    font-size: 0.8em;
    span {
      font-weight: bold;
      display: block; } } }


.big-icons {
  display: flex;
  align-items: center;
  margin-top: 24px;

  li {
    list-style: none;
    width: 40%;
    position: relative;

    &.firstOne {
      margin-right: 50px;
      margin-left: -20px;

      &::after {
        content: " ";
        display: block;
        border-top: 5px dotted var(--pm-dark);
        width: 40px;
        position: absolute;
        right: -40px;
        top: 40px; } }


    img {
      width: 100%; } } }
